.back-home-button {
    cursor: pointer;
    width: 80px;
    height: 40px;
    line-height: 40px;
    border-radius: 10px;
    text-align: center;
    font-size: 15px;
    position: absolute;
    top: 10px;
    left: 50%;
}