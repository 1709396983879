  .project-design-system {
    margin: 0;
    width: 100;
    box-sizing: border-box;
    --max-width-m: 1280px;
    --max-width-s: 920px
  }
  
  .cover {
    min-height: 472px
  }
  
  .cover__preview {
    margin-bottom: var(--space-l)
  }
  
  .cover .image {
    height: 180px
  }
  
  .event {
    min-height: 400px
  }
  
  .event__content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
  }
  
  .event__preview {
    margin-bottom: var(--space-xxl);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center
  }
  
  .event__title {
    margin-bottom: var(--space-s)
  }
  
  .event .brand-logo {
    margin-left: var(--space-xs)
  }
  
  @media (max-width:383px) {
    .event {
      min-height: 500px
    }
  }
  
  .design-system-footer {
    padding: var(--space-xxxl) var(--gap);
    position: relative
  }
  
  .footer__content {
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: var(--max-width-m)
  }
  
  .design-system-header {
    padding: var(--space-xxxl) var(--gap)
  }
  
  .header__content {
    width: 100%;
    margin: 0 auto;
    max-width: var(--max-width-m);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between
  }
  
  .header__logo {
    width: 179px;
    height: 34px;
    background-color: var(--primary-color)
  }
  
  .history {
    border-width: 3px
  }
  
  .history__show {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between
  }
  
  .history__details {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
  }
  
  .onoffswitch {
    width: 97px;
    height: 28px;
    border: 2px solid var(--border-bg-color);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  .onoffswitch__prompt {
    cursor: pointer;
    padding: 3px;
    font-size: 18px;
    text-align: center;
    height: 20px;
    width: 95px;
    background-color: lightsalmon;
    position: absolute;
    border-radius: 10px;
    top: 10px; 
  } 

  .onoffswitch__prompt::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 20px solid lightsalmon;
    position: absolute;
    top: 18px;
    right: 20px;
    transform: rotate(-50deg);    
  }

  .onoffswitch.onoffswitch_checked {
    -ms-flex-pack: end;
    justify-content: flex-end
  }
  
  .onoffswitch__button {
    width: 24px;
    height: 24px;
    margin: 2px;
    background-color: var(--border-bg-color)
  }
  
  .payment {
    border-width: 3px;
    height: 100%
  }
  
  .product {
    min-height: 300px;
    border-width: 3px
  }
  
  .product__title {
    margin-bottom: var(--space-xxxl)
  }
  
  .product__image {
    background-color: var(--default-bg-color)
  }
  
  .product .image {
    height: 160px;
    border-color: var(--default-bg-color)
  }
  
  .warning {
    min-height: 335px;
    height: 100%
  }
  
  .warning .placeholder {
    margin-top: var(--space-m);
    margin-bottom: var(--space-m)
  }
  
  @media (max-width:354px) {
    .warning {
      min-height: 365px
    }
  }
  
  @media (max-width:323px) {
    .warning {
      min-height: 400px
    }
  }
  
  @media (max-width:280px) {
    .warning {
      min-height: 430px
    }
  }
  
  .avatar {
    background-color: var(--primary-color)
  }
  
  .avatar_size_m {
    width: 60px;
    height: 60px
  }
  
  .brand-logo_name_blue {
    background-color: #5dcdf9
  }
  
  .brand-logo_name_green {
    background-color: #23b324
  }
  
  .brand-logo_name_red {
    background-color: #f33
  }
  
  .brand-logo_name_purple {
    background-color: #c62cff
  }
  
  .brand-logo_size_xxs {
    width: 24px;
    height: 24px
  }
  
  .brand-logo_size_xs {
    width: 32px;
    height: 32px
  }
  
  .brand-logo_size_s {
    width: 40px;
    height: 40px
  }
  
  .brand-logo_size_m {
    width: 60px;
    height: 60px
  }
  
  .brand-logo_size_l {
    width: 80px;
    height: 80px
  }
  
  .button {
    background-color: var(--control-bg-color)
  }
  
  .button_size_s {
    width: 200px;
    height: 24px
  }
  
  .button_size_m {
    width: 200px;
    height: 32px
  }
  
  .button_size_l {
    width: 200px;
    height: 42px
  }
  
  .button_size_xl {
    width: 200px;
    height: 56px
  }
  
  .button_size_xxl {
    width: 200px;
    height: 64px
  }
  
  .button_width_full {
    width: 100%
  }
  
  .card {
    position: relative
  }
  
  .card_border_all {
    border: 3px solid var(--border-bg-color)
  }
  
  .card_view_default {
    background-color: var(--default-bg-color)
  }
  
  .card__content {
    position: relative
  }
  
  .card__content_distribute_center {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center
  }
  
  .card__content_vertical-align_center {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center
  }
  
  .card__content_space-a_m {
    padding: var(--space-m)
  }
  
  .card__content_space-a_l {
    padding: var(--space-l)
  }
  
  .card__content_space-a_xl {
    padding: var(--space-xl)
  }
  
  .card__content_space-a_xxl {
    padding: var(--space-xxl)
  }
  
  .card__content_space-a_xxxl {
    padding: var(--space-xxxl)
  }
  
  .card__footer {
    position: absolute;
    bottom: 0;
    width: 100%
  }
  
  .card__footer_distribute_between {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between
  }
  
  .card__footer_distribute_center {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center
  }
  
  .card__footer_distribute_right {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: end;
    justify-content: flex-end
  }
  
  .card__footer_vertical-align_center {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center
  }
  
  .card__footer_vertical-align_top {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: start;
    align-items: flex-start
  }
  
  .card__footer_vertical-align_bottom {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: end;
    align-items: flex-end
  }
  
  .card__footer_space-a_m {
    padding: var(--space-m)
  }
  
  .card__footer_space-a_xl {
    padding: var(--space-xl)
  }
  
  .card__footer_space-a_l {
    padding: var(--space-l)
  }
  
  .card__footer_space-a_xxl {
    padding: var(--space-xxl)
  }
  
  .e-accordion__short {
    cursor: pointer
  }
  
  .e-accordion__more {
    display: none
  }
  
  .form_border_all {
    border: 3px solid var(--border-bg-color)
  }
  
  .form_view_default {
    background-color: var(--default-bg-color)
  }
  
  .form__item_border_bottom {
    border-bottom: 3px solid var(--border-bg-color)
  }
  
  .form__item_border_bottom:last-of-type {
    border-bottom: none
  }
  
  .form__item_distribute_between {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between
  }
  
  .form__item_vertical-align_center {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center
  }
  
  .form__item_indent-b_xxxs {
    margin-bottom: var(--space-xxxs)
  }
  
  .form__item_indent-b_xxxs:last-of-type {
    margin-bottom: 0
  }
  
  .form__item_indent-b_xxs {
    margin-bottom: var(--space-xxs)
  }
  
  .form__item_indent-b_xxs:last-of-type {
    margin-bottom: 0
  }
  
  .form__item_indent-b_s {
    margin-bottom: var(--space-s)
  }
  
  .form__item_indent-b_s:last-of-type {
    margin-bottom: 0
  }
  
  .form__item_indent-b_m {
    margin-bottom: var(--space-m)
  }
  
  .form__item_indent-b_m:last-of-type {
    margin-bottom: 0
  }
  
  .form__item_indent-b_l {
    margin-bottom: var(--space-l)
  }
  
  .form__item_indent-b_l:last-of-type {
    margin-bottom: 0
  }
  
  .form__item_indent-b_xl {
    margin-bottom: var(--space-xl)
  }
  
  .form__item_indent-b_xl:last-of-type {
    margin-bottom: 0
  }
  
  .form__item_indent-b_xxl {
    margin-bottom: var(--space-xxl)
  }
  
  .form__item_indent-b_xxl:last-of-type {
    margin-bottom: 0
  }
  
  .form__item_indent-b_xxxl {
    margin-bottom: var(--space-xxxl)
  }
  
  .form__item_indent-b_xxxl:last-of-type {
    margin-bottom: 0
  }
  
  .form__item_indent-b_xxxxl {
    margin-bottom: var(--space-xxxxl)
  }
  
  .form__item_indent-b_xxxxl:last-of-type {
    margin-bottom: 0
  }
  
  .form__item_space-h_xxxs {
    padding-left: var(--space-xxxs);
    padding-right: var(--space-xxxs)
  }
  
  .form__item_space-h_xxs {
    padding-left: var(--space-xxs);
    padding-right: var(--space-xxs)
  }
  
  .form__item_space-h_s {
    padding-left: var(--space-s);
    padding-right: var(--space-s)
  }
  
  .form__item_space-h_m {
    padding-left: var(--space-m);
    padding-right: var(--space-m)
  }
  
  .form__item_space-h_l {
    padding-left: var(--space-l);
    padding-right: var(--space-l)
  }
  
  .form__item_space-h_xl {
    padding-left: var(--space-xl);
    padding-right: var(--space-xl)
  }
  
  .form__item_space-h_xxl {
    padding-left: var(--space-xxl);
    padding-right: var(--space-xxl)
  }
  
  .form__item_space-h_xxxl {
    padding-left: var(--space-xxxl);
    padding-right: var(--space-xxxl)
  }
  
  .form__item_space-h_xxxxl {
    padding-left: var(--space-xxxxl);
    padding-right: var(--space-xxxxl)
  }
  
  .form__item_space-v_xxxs {
    padding-top: var(--space-xxxs);
    padding-bottom: var(--space-xxxs)
  }
  
  .form__item_space-v_xxs {
    padding-top: var(--space-xxs);
    padding-bottom: var(--space-xxs)
  }
  
  .form__item_space-v_s {
    padding-top: var(--space-s);
    padding-bottom: var(--space-s)
  }
  
  .form__item_space-v_m {
    padding-top: var(--space-m);
    padding-bottom: var(--space-m)
  }
  
  .form__item_space-v_l {
    padding-top: var(--space-l);
    padding-bottom: var(--space-l)
  }
  
  .form__item_space-v_xl {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl)
  }
  
  .form__item_space-v_xxl {
    padding-top: var(--space-xxl);
    padding-bottom: var(--space-xxl)
  }
  
  .form__item_space-v_xxxl {
    padding-top: var(--space-xxxl);
    padding-bottom: var(--space-xxxl)
  }
  
  .form__item_space-v_xxxxl {
    padding-top: var(--space-xxxxl);
    padding-bottom: var(--space-xxxxl)
  }
  
  .form__label_width_default {
    width: 35%
  }
  
  .form__control {
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end
  }
  
  .grid {
    width: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr
  }
  
  .grid_col-gap_two-thirds {
    grid-column-gap: calc(var(--gap) * 2 / 3)
  }
  
  .grid_col-gap_full {
    grid-column-gap: var(--gap)
  }
  
  .grid_col-gap_half {
    grid-column-gap: calc(var(--gap)/ 2)
  }
  
  .grid_row-gap_half {
    grid-row-gap: calc(var(--gap)/ 2)
  }
  
  .grid_row-gap_full {
    grid-row-gap: var(--gap)
  }
  
  @media (min-width:1024px) {
    .grid_m-columns_12 {
      -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: repeat(12, 1fr);
      grid-auto-flow: row
    }
  
    .grid_m-columns_10 {
      -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: repeat(10, 1fr);
      grid-auto-flow: row
    }
  
    .grid__fraction_m-col_2 {
      -ms-grid-column-span: 2;
      grid-column: span 2
    }
  
    .grid__fraction_m-col_3 {
      -ms-grid-column-span: 3;
      grid-column: span 3
    }
  
    .grid__fraction_m-col_4 {
      -ms-grid-column-span: 4;
      grid-column: span 4
    }
  
    .grid__fraction_m-col_5 {
      -ms-grid-column-span: 5;
      grid-column: span 5
    }
  
    .grid__fraction_m-col_8 {
      -ms-grid-column-span: 8;
      grid-column: span 8
    }
  }
  
  .icon-plus_vertical-align_center {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center
  }
  
  .icon-plus__icon_indent-r_s {
    margin-right: var(--space-s)
  }
  
  .icon-plus__icon_indent-r_m {
    margin-right: var(--space-m)
  }
  
  .icon-plus__icon_indent-l_l {
    margin-left: var(--space-l)
  }
  
  .image {
    width: 100%;
    border: 3px solid var(--border-bg-color)
  }
  
  .product__image.theme_color_project-default .image,
  .theme_color_project-default .image {
    background: url("data:image/svg+xml;utf8,<svg width='80px' height='64px' viewBox='0 0 80 64' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><title>placeholder</title><desc>Created with Sketch.</desc><g id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='image-placeholder' transform='translate(-500.000000, -368.000000)' fill='%23000000' fill-rule='nonzero'><g id='Group' transform='translate(500.000000, 368.000000)'><g id='placeholder'><path d='M32,16 L48,16 L48,8 L56,8 L56,16 L64,16 L64,24 L72,24 L72,32 L80,32 L80,48 L72,48 L72,40 L64,40 L64,48 L56,48 L56,56 L48,56 L48,48 L32,48 L32,56 L24,56 L24,48 L16,48 L16,40 L8,40 L8,48 L0,48 L0,32 L8,32 L8,24 L16,24 L16,16 L24,16 L24,8 L32,8 L32,16 Z M24,32 L32,32 L32,24 L24,24 L24,32 Z M16,0 L24,0 L24,8 L16,8 L16,0 Z M16,56 L24,56 L24,64 L16,64 L16,56 Z M56,32 L56,24 L48,24 L48,32 L56,32 Z M64,0 L64,8 L56,8 L56,0 L64,0 Z M64,56 L64,64 L56,64 L56,56 L64,56 Z' id='Shape'></path></g></g></g></g></svg>") no-repeat center
  }
  
  .product__image.theme_color_project-brand .image,
  .product__image.theme_color_project-inverse .image,
  .theme_color_project-brand .image,
  .theme_color_project-inverse .image {
    background: url("data:image/svg+xml;utf8,<svg width='80px' height='64px' viewBox='0 0 80 64' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><title>placeholder</title>    <desc>Created with Sketch.</desc><g id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='image-placeholder' transform='translate(-620.000000, -368.000000)' fill='%23FFFFFF' fill-rule='nonzero'><g id='Group' transform='translate(500.000000, 368.000000)'><g id='placeholder' transform='translate(120.000000, 0.000000)'><path d='M32,16 L48,16 L48,8 L56,8 L56,16 L64,16 L64,24 L72,24 L72,32 L80,32 L80,48 L72,48 L72,40 L64,40 L64,48 L56,48 L56,56 L48,56 L48,48 L32,48 L32,56 L24,56 L24,48 L16,48 L16,40 L8,40 L8,48 L0,48 L0,32 L8,32 L8,24 L16,24 L16,16 L24,16 L24,8 L32,8 L32,16 Z M24,32 L32,32 L32,24 L24,24 L24,32 Z M16,0 L24,0 L24,8 L16,8 L16,0 Z M16,56 L24,56 L24,64 L16,64 L16,56 Z M56,32 L56,24 L48,24 L48,32 L56,32 Z M64,0 L64,8 L56,8 L56,0 L64,0 Z M64,56 L64,64 L56,64 L56,56 L64,56 Z' id='Shape'></path></g></g></g></g></svg>") no-repeat center
  }
  
  .informer {
    position: relative
  }
  
  .informer_border_all {
    border: 3px solid var(--border-bg-color)
  }
  
  .informer_view_default {
    background-color: var(--default-bg-color)
  }
  
  .informer__content_distribute_default {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: start;
    justify-content: flex-start
  }
  
  .informer__content_distribute_between {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between
  }
  
  .informer__content_distribute_center {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center
  }
  
  .informer__content_space-a_xl {
    padding: var(--space-xl)
  }
  
  .informer__content_space-a_xxl {
    padding: var(--space-xxl)
  }
  
  .informer__action {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0
  }
  
  .informer__action_distribute_default {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: start;
    justify-content: flex-start
  }
  
  .informer__action_distribute_between {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between
  }
  
  .informer__action_distribute_center {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center
  }
  
  .informer__action_space-a_xl {
    padding: var(--space-xl)
  }
  
  .informer__action_space-a_xxl {
    padding: var(--space-xxl)
  }
  
  .input {
    border-width: 3px;
    border-style: solid
  }
  
  .input_size_s {
    width: 100%;
    height: 24px
  }
  
  .input_size_m {
    width: 100%;
    height: 32px
  }
  
  .input_size_l {
    width: 100%;
    height: 42px
  }
  
  .input_size_xl {
    width: 100%;
    height: 56px
  }
  
  .input_size_xxl {
    width: 100%;
    height: 64px
  }
  
  .layout {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: var(--default-bg-color)
  }
  
  .layout__container {
    width: 100%;
    background-color: var(--default-bg-color);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
  }
  
  .layout__container_align_center {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center
  }
  
  .layout__container_size_s {
    max-width: var(--max-width-s)
  }
  
  .layout__container_size_m {
    max-width: var(--max-width-m)
  }
  
  .layout__container_space-v_xxxxl {
    padding-top: var(--space-xxxxl);
    padding-bottom: var(--space-xxxxl)
  }
  
  .layout__container_space-v_xxxxxl {
    padding-top: var(--space-xxxxxl);
    padding-bottom: var(--space-xxxxxl)
  }
  
  .layout__container_indent-b_xxl {
    margin-bottom: var(--space-xxl)
  }
  
  .layout__container_indent-b_xxxxl {
    margin-bottom: var(--space-xxxxl)
  }
  
  .list_border_all {
    border: 3px solid var(--border-bg-color)
  }
  
  .list_view_default {
    background-color: var(--default-bg-color)
  }
  
  .list__item_border_bottom {
    border-bottom: 3px solid var(--border-bg-color)
  }
  
  .list__item_border_bottom:last-of-type {
    border-bottom: none
  }
  
  .list__item_border_top {
    border-top: 3px solid var(--border-bg-color)
  }
  
  .list__item_distribute_between {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between
  }
  
  .list__item_vertical-align_center {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center
  }
  
  .list__item_indent-t_m {
    margin-top: var(--space-m)
  }
  
  .list__item_indent-t_m:first-of-type {
    margin-top: 0
  }
  
  .list__item_indent-b_l {
    margin-bottom: var(--space-l)
  }
  
  .list__item_indent-b_l:last-of-type {
    margin-bottom: 0
  }
  
  .list__item_indent-b_s {
    margin-bottom: var(--space-s)
  }
  
  .list__item_indent-b_m {
    margin-bottom: var(--space-m)
  }
  
  .list__item_indent-b_xl {
    margin-bottom: var(--space-xl)
  }
  
  .list__item_space-b_s {
    padding-bottom: var(--space-s)
  }
  
  .list__item_space-a_m {
    padding: var(--space-m)
  }
  
  .placeholder {
    background-color: var(--primary-color)
  }
  
  .placeholder_size_m {
    width: 72px;
    height: 72px
  }
  
  .placeholder_view_primary {
    background-color: var(--primary-color)
  }
  
  .section_indent-b_xxl {
    margin-bottom: var(--space-xxl)
  }
  
  .section_indent-b_xxxxl {
    margin-bottom: var(--space-xxxxl)
  }
  
  .section_space-v_xxl {
    padding-top: var(--space-xxl);
    padding-bottom: var(--space-xxl)
  }
  
  .section_space-v_xxxxl {
    padding-top: var(--space-xxxxl);
    padding-bottom: var(--space-xxxxl)
  }
  
  .text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }
  
  .text_align_center {
    -ms-flex-pack: center;
    justify-content: center
  }
  
  .text_size_s {
    font-size: var(--text-height-s)
  }
  
  .text_size_s>.text__word {
    margin-top: 8px;
    margin-bottom: 5px;
    height: 8px
  }
  
  .text_size_s>.text__word_width_s {
    width: 20px
  }
  
  .text_size_s>.text__word_width_m {
    width: 48px
  }
  
  .text_size_s>.text__word_width_l {
    width: 100px
  }
  
  .text_size_m {
    font-size: var(--text-height-m)
  }
  
  .text_size_m>.text__word {
    margin-top: 8px;
    margin-bottom: 7px;
    height: 10px
  }
  
  .text_size_m>.text__word_width_s {
    width: 24px
  }
  
  .text_size_m>.text__word_width_m {
    width: 56px
  }
  
  .text_size_m>.text__word_width_l {
    width: 114px
  }
  
  .text_size_l {
    font-size: var(--text-height-l)
  }
  
  .text_size_l>.text__word {
    margin-top: 8px;
    margin-bottom: 9px;
    height: 11px
  }
  
  .text_size_l>.text__word_width_s {
    width: 28px
  }
  
  .text_size_l>.text__word_width_m {
    width: 68px
  }
  
  .text_size_l>.text__word_width_l {
    width: 138px
  }
  
  .text_size_xl {
    font-size: var(--text-height-xl)
  }
  
  .text_size_xl>.text__word {
    margin-top: 9px;
    margin-bottom: 12px;
    height: 13px
  }
  
  .text_size_xl>.text__word_width_s {
    width: 32px
  }
  
  .text_size_xl>.text__word_width_m {
    width: 74px
  }
  
  .text_size_xl>.text__word_width_l {
    width: 154px
  }
  
  .text_size_xxl {
    font-size: var(--text-height-xxl)
  }
  
  .text_size_xxl>.text__word {
    margin-top: 8px;
    margin-bottom: 14px;
    height: 16px
  }
  
  .text_size_xxl>.text__word_width_s {
    width: 36px
  }
  
  .text_size_xxl>.text__word_width_m {
    width: 88px
  }
  
  .text_size_xxl>.text__word_width_l {
    width: 184px
  }
  
  .text_type_h1 {
    margin: 18px 0 24px
  }
  
  .text_type_h2 {
    margin: 45px 0 21px
  }
  
  .text_type_h3 {
    margin: 42px 0 0
  }
  
  .text_type_p {
    margin: 15px 0 21px
  }
  
  .text_view_primary {
    color: var(--primary-color)
  }
  
  .text_view_secondary {
    color: var(--secondary-color)
  }
  
  .text_view_ghost {
    color: var(--ghost-color)
  }
  
  .text_view_link {
    color: var(--link-color)
  }
  
  .text__word {
    background-color: currentColor
  }
  
  .theme {
    background-color: var(--default-bg-color);
    border-color: var(--border-bg-color);
    color: var(--primary-color)
  }
  
  .theme_color_project-default {
    --default-bg-color: #FFFFFF;
    --control-bg-color: #ffcc00;
    --border-bg-color: #000000;
    --primary-color: #000000;
    --secondary-color: rgba(0, 0, 0, 0.6);
    --ghost-color: rgba(0, 0, 0, 0.3);
    --link-color: #0077ff
  }
  
  .theme_color_project-brand {
    --default-bg-color: #0077ff;
    --control-bg-color: #FFFFFF;
    --border-bg-color: #FFFFFF;
    --primary-color: #FFFFFF;
    --secondary-color: rgba(255, 255, 255, 0.6);
    --ghost-color: rgba(255, 255, 255, 0.3);
    --link-color: #FFFFFF
  }
  
  .theme_color_project-inverse {
    --default-bg-color: #000000;
    --control-bg-color: #FFFFFF;
    --border-bg-color: #FFFFFF;
    --primary-color: #FFFFFF;
    --secondary-color: rgba(255, 255, 255, 0.6);
    --ghost-color: rgba(255, 255, 255, 0.3);
    --link-color: #0077ff
  }
  
  .theme_color_project-warning {
    --default-bg-color: #ff3333;
    --control-bg-color: #FFFFFF;
    --border-bg-color: #ff3333;
    --primary-color: #FFFFFF;
    --secondary-color: rgba(255, 255, 255, 0.6);
    --ghost-color: rgba(255, 255, 255, 0.3);
    --link-color: #FFF
  }
  
  .theme_size_default {
    --text-height-s: 8px;
    --text-height-m: 10px;
    --text-height-l: 11px;
    --text-height-xl: 13px;
    --text-height-xxl: 16px;
    --line-height-s: 21px;
    --line-height-m: 25px;
    --line-height-l: 28px;
    --line-height-xl: 34px;
    --line-height-xxl: 38px
  }
  
  .theme_space_default {
    --space-xxxs: 3px;
    --space-xxs: 6px;
    --space-xs: 9px;
    --space-s: 15px;
    --space-m: 18px;
    --space-l: 24px;
    --space-xl: 27px;
    --space-xxl: 36px;
    --space-xxxl: 45px;
    --space-xxxxl: 51px;
    --space-xxxxxl: 72px
  }
  
  .theme_gap_small {
    --gap: var(--space-s)
  }
  
  @media (min-width:1280px) {
    .theme_gap_small {
      --gap: var(--space-xl)
    }
  }
  
  .theme .back-home-button {
    background-color: lightsalmon;
  }

  .theme .back-home-button:hover {
    background-color: rgb(228, 117, 73);
  }

  .theme_color_project-default .back-home-button,
  .theme_color_project-inverse .back-home-button {
    color: var(--primary-color);
  }