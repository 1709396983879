.projects {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 10px;
}

@media screen and (max-width: 1200px) {
	.projects {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 860px) {
    .projects {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 580px) {
	.projects {
		grid-template-columns: 1fr;
	}
}
