.home-page .main {
	padding-top: 68px;
	width: 100%;
}

.main__subsection {
	padding: 20px 20px 50px;
	margin-top: -5px;
}

.main__subsection:nth-of-type(1) {
	margin-top: 8px;
}

.main__subsection:last-of-type {
	margin-bottom: 150px;
}

.main__subsection:nth-of-type(even) {
	background-color: var(--home-light-color);
	color: var(--home-dark-color);
}

.main__subsection:nth-of-type(odd) {
	background-color: var(--home-dark-color);
	color: var(--home-light-color);
}

.subsection__heading {
	font-size: 35px;
	padding: 30px 10px;
	text-align: center;
}

a {
	color: inherit;
	text-decoration: inherit;
}

button:focus {
	outline: 0;
	/*box-shadow: inset 0 0 10px 0 grey; */
}

.caption {
	font-size: 25px;
	text-decoration: underline;
	text-align: left;
}

@media screen and (max-width: 1024px) {
	.main__subsection:last-of-type {
		margin-bottom: 30px;
	}
}

@media screen and (max-width: 780px) {
	.home-page .main {
		padding-top: 50px;
	}
	.caption {
		font-size: 18px;
	}
	#menu-box{
		flex-direction: column;
	}
	.main__subsection {
		margin-top: -22px;
	}
	.subsection__heading {
		padding: 10px;
	}
	.main__subsection:last-of-type {
		margin-bottom: 10px;
	}
}
