:root {
	--home-light-color: #d8e3eb;
	--home-dark-color: #1a405c;
	--home-background-color: var(--home-dark-color);
	--home-text-color: var(--home-light-color);
	--home-inverted-background-color: var(--home-light-color);
	--home-inverted-text-color: var(--home-dark-color);
	--header-background-color: #f9f8f8;
	--header-text-color: var(--home-dark-color);
	--header-hover-button-effect: linear-gradient(90deg, #94bbe9, #94e3e9, #94bbe9);
	--footer-height: 16px;
	--footer-padding: 10px;
}

* {
	margin: 0;
	padding: 0;
}

html {
	border: none;
	scroll-behavior: smooth;
}

body {
	font-family: Helvetica, Arial, sans-serif;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

:target::before {
	content: "";
	display: block;
	height: 67px; 
	margin: -80px 0 0; 
}

.home-page {
	background-color: var(--home-background-color);
	color: var(--home-text-color);
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.home-page .header {
	order: 1;
}

.home-page .main {
	order: 2;
}

.home-page .footer {
	order: 3;	
}
