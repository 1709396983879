
.home-page .header {
	width: 100%;
	position: fixed;
	top: 0;
	background-color: var(--header-background-color);
	color: var(--header-text-color);	
	border-bottom: solid 1px silver;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.header__caption {
	float: left;
	font-family: Roboto, Helvetica, sans-serif;
	font-display: swap;
	font-size: 25px;
	padding: 5px;
	padding-left: 1%;
	margin: 15px;
}

.header__btns {	
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-right: 1%;
}

.header__mobile-menu {
	display: none;
}

@media screen and (max-width: 1024px) {
	.header__caption {
		font-size: 20px;
	}
}

@media screen and (max-width: 780px) {
	.home-page .header {
		position: fixed;
		left: 0;
		top: 0;
		padding: 0;
	}
	.header__btns {
		display: none;
	}	    
	.header__mobile-menu {
		display: flex;
		justify-content: flex-end;
		padding: 2px;
		margin-top: 6px;
		margin-right: 5px;
	}
	.header__menu-btn {
		font-size: 25px;
		border: none;
		background-color: transparent;
	}
	.header__caption {
		margin: 5px;
	}
}

