.binary-calc-heading {
  width: 100%;
  padding: 40px 0;
  text-align: center;
  color: darkblue;
}

.binary-calc-section {
    margin: auto;
    width: 33%;
}

#res {
    background-color: lightsteelblue;
    border: solid 1px grey;
    border-radius: 8px;
    height: 48px;
    font-size: 20px;
}

#btn0, #btn1 {
    background-color: lightskyblue;
    color: brown;
}

#btnClr, #btnEql {
    background-color: darkslateblue;
    color: white;
}

#btnSum, #btnSub, #btnMul, #btnDiv {
    background-color: grey;
    color: white;
}

.btn {
    cursor: pointer;
    width: 25%;
    height: 36px;
    font-size: 18px;
    margin: 0px;
    float: left;
    border-radius: 5px;
}

.binary-calc-home-link {
    color: darkslateblue;
    font-weight: bold;
    border: 2px solid darkslateblue;
    border-radius: 10px;
    padding: 10px 20px;
    margin-bottom: 30px;
}

.binary-calc-home-link:hover {
    background-color: darkslateblue;
    color: white;
}

.binary-calc-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 42px;
}

.binary-calc-page {
    display: flex;
    flex-direction: column;
}

.binary-calc-page .binary-calc-content {
    min-height: calc(100vh - var(--footer-height) - var(--footer-padding) - var(--footer-padding));
    order: 1;
}

.binary-calc-page .footer {
    order: 2;
}
