.footer {
	background-color: var(--home-inverted-background-color);
	color: var(--home-inverted-text-color);
    width: 100%;
	height: var(--footer-height);
	padding: var(--footer-padding);
	text-align: center;
}

.footer-pin {
	position: relative;
	left: 0;
	bottom: 0;
  }

