
.aboutme__photo {
	width: 320px;
	height: 320px;
	padding: 10px 10px 10px 10px;
	margin-right: 20px;
	border-radius: 50%;
}

@supports not (display: grid) {
	.aboutme__photo {
		float: left;
	}
}

.aboutme__text {
	font-size: 20px;
	line-height: 30px;
	text-align: justify;
	padding: 5px 10px 20px 10px;
}

.aboutme__paragraph {
    text-indent: 20px;
	line-height: 1.8rem;
}

.subsection__aboutme {
	display: grid;
	grid-template-columns: 1fr 3fr;
	justify-items: center;
	align-items: center;
}

@media screen and (max-width: 780px) {
	.aboutme__text {
		font-size: 18px;
		margin-top: 20px;
	}	
	.aboutme__text {
		line-height: 22px;
	}
	.subsection__aboutme {
		grid-template-columns: 1fr;
	}
	.aboutme__photo {
		width: 250px;
		height: 250px;
	}
}
