
.header__btn {
	width: max-content;
	font-size: 25px;
	font-weight: bold;
	border: none;
	background-color: inherit;
	margin: 15px 0;
	padding: 5px 15px;
	font-family: Roboto, Helvetica, sans-serif;
	font-display: swap;
	color: var(--home-inverted-text-color);
	animation: slidebg 4s linear infinite alternate;
}

@media screen and (max-width: 1024px) {
	.header__btn {
		font-size: 20px;
	}
}

@media screen and (max-width: 780px) { 
	.header__btn {
		text-decoration: underline;
		margin: 2px 10px;
	}     
}

@keyframes slidebg {
	to {
	  background-position: 20vw;
	}
}

@media screen and (max-width: 780px) { 
	@keyframes slidebg {
		to {
		  background-position: 100vw;
		}
	}	
}