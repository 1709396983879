
.project a {
	margin: 20px 0;
	padding: 8px;
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
}

.icon {
	height: 130px;
	width: 260px;
	padding: 15px 0;
}

.project__memoji-game > a > .icon {
	background: url('project__memoji-game.png') no-repeat center;
}

.project__binary-calculator > a > .icon {
	background: url('project__binary-calculator.png') no-repeat center;
}

.project__pianoles > a > .icon {
	background: url('project__pianoles.png') no-repeat center;
}

.project__wordpress-blog > a > .icon {
	background: url('project__wordpress-blog.png') no-repeat center;
}

.project__smartphone > a > .icon {
	background: url('project__smartphone.png') no-repeat center;
}

.project__tribute-page > a > .icon {
	background: url('project__tribute-page.png') no-repeat center;
}

.project__cacao-bu > a > .icon {
	background: url('project__cacao-bu.png') no-repeat center;
}

.project__design-system > a > .icon {
	background: url('project__design-system.png') no-repeat center;
}

.project__tech-blog-vue > a > .icon {
	background: url('project__tech-blog-vue.png') no-repeat center;
}

.project__angular-cargo > a > .icon {
	background: url('project__angular-cargo.png') no-repeat center;
}

.project__parallax-tailwind > a > .icon {
	background: url('project__parallax-tailwind.png') no-repeat center;
}


.caption.project-caption_text-center {
	text-align: center;
}
